<template>
	<div>
		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			@click:row="handleClick"
			class="elevation-1 request_list">
		</v-data-table>
	</div>
</template>

<script>
	export default {
        mounted() {
            this.getData();
        },
		data: () => ({
			isLoading: true,
			requestHeaders: [],
			requestList: []
		}),
		methods: {
			handleClick(item) {
				this.$router.push({ path: '/department/kindergarten/'+item.id });
			},
			async getData() {
				await this.$http.get('/kindergarten/all').then((response) => {
					this.requestHeaders = response.data.data.kindergartenList.headers;
					this.requestList = response.data.data.kindergartenList.list;
					this.isLoading = false;
				});
			}
			
		}
  }
</script>

<style scoped>

</style>